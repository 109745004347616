import moment from 'moment';
import React,{useState,useEffect} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import AlumniNewsList from '../components/AlumniNewsList';
import comunicationServices from '../comunicationServices';

function AlumniNewsPage() {

    const [newsList,setNewsList]    = useState(null);
    const getNewsList = () => {
        comunicationServices.getNewsList().then(res =>{
            setNewsList(res.data);
        }).catch((error)=>{
        }).finally(()=>{
        })
    }

    useEffect(()=>{
        getNewsList();
    },[])

    return (
        <AppLayout>
            <div className="container-fluid">
                {newsList && <AlumniNewsList newsList={newsList} />}
            </div>
        </AppLayout>
    )
}

export default AlumniNewsPage