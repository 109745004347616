import React from 'react'
import moment from 'moment';

function AlumniEventsList({eventsList}) {
    return (
        <div className="row mt-3">
            <div className="col-md-12">
                <h4 className="text-primary">Upcomming Events</h4>
            </div>
            <div className="col-md-12">
                {eventsList && eventsList.map(event=>
                    <div className="card mb-2" key={event.id}>
                        <div className="card-header">
                            <strong>{event.title}</strong>
                        </div>
                        <div className="card-body">
                                {event.startDate !== event.endDate ?
                                    <div className="row">
                                        <div className="col-1 text-center">
                                            <div className="card bg-primary text-white text-center" style={{width:80}} >
                                                <div className="card-header p-1">{ moment(event.startDate).format("D")}</div>
                                                <div className="card-body p-1">{ moment(event.startDate).format("MMM")}</div>
                                            </div>
                                        </div>
                                        <div className="col-11">
                                            {event.details}
                                        </div>
                                    </div>
                                    :
                                    <div className="row">
                                        <div className="col-3 text-center">
                                            <div className="d-flex justify-content-around">
                                                <div>
                                                    <div className="card bg-primary text-white text-center" style={{width:80}}>
                                                        <div className="card-header p-1">{ moment(event.startDate).format("D")}</div>
                                                        <div className="card-body p-1">{ moment(event.startDate).format("MMM")}</div>
                                                    </div>
                                                </div>
                                                
                                                <h3 className="mt-3">To</h3>
                                                
                                                <div>
                                                    <div className="card bg-primary text-white text-center" style={{width:80}}>
                                                        <div className="card-header p-1">{ moment(event.endDate).format("D")}</div>
                                                        <div className="card-body p-1">{ moment(event.endDate).format("MMM")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-9">
                                            {event.details}
                                        </div>
                                    </div>
                                }
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AlumniEventsList