import React,{useState,useEffect} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import AlumniEventsList from '../components/AlumniEventsList';
import comunicationServices from '../comunicationServices';

function AlumniEventsPage() {

    const [eventsList,setEventsList]    = useState(null);
    const getEventsList = () => {
        comunicationServices.getEventsList().then(res =>{
            setEventsList(res.data);
        }).catch((error)=>{
        }).finally(()=>{
        })
    }

    useEffect(()=>{
        getEventsList();
    },[]);


    return (
        <AppLayout>
            <div className="container-fluid">
                {eventsList && <AlumniEventsList eventsList={eventsList} />}
            </div>
        </AppLayout>
    )
}

export default AlumniEventsPage