import {useEffect, useState} from 'react'
import { connect } from 'react-redux';
import { startSession } from '../../redux/authStore';
import { useNavigate } from 'react-router';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../components/FormikControl/FormikControl';
import alumniAuthServices from '../alumniAuthServices';


const AlumniRegister = (props) => {

  const [error,setError] = useState(false);
  const [errorMsg,setErrorMsg] = useState("");

  const initialValues = {
    firstName :'',
    lastName:'',
    email:'',
    password:'',
  }

  const validationRule = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email('Must be a valid email').required("Email is Required"),
    password: Yup.string().required("Required"),
  });

  const navigate = useNavigate();

  const { startSession } = props;

  const onSubmit = (values, onSubmitProps) => {
    alumniAuthServices.doSignup(values).then( (res) => {
      startSession();
      navigate("/");
    }).catch( error =>{
      setError(true);
      setErrorMsg(error.message);
      //console.log(error);
    })
  }

  useEffect(()=>{  
    if(localStorage.authUser){
      startSession();
      navigate("/");
    }
  },[startSession,navigate])

  const enabled = true;
  return (
    
    <>
      {!enabled && <h3>Currently Disabled</h3> }
      {enabled &&
      <div className="container">
        <div className="row justify-content-center mt-5">
          <div className="col-md-6">

            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-center">
                    <h4>Alumni Register</h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationRule}
                  onSubmit={onSubmit}
                  enableReinitialize validateOnMount
                >
                  {
                    (formik) => {
                      //console.log(formik.values);
                      return (
                        
                        <Form>
                            
                          {error &&
                            <div className="col-md-12">
                              <div className="alert alert-danger">
                                <strong>!</strong> {errorMsg}
                              </div>
                            </div>
                          }

                            <FormikControl control="input" formik={formik} type="text"
                                label="First Name" name="firstName"
                                className="col-sm-12 col-xs-12 mt-4"
                            />

                            <FormikControl control="input" formik={formik} type="text"
                                label="Last Name" name="lastName"
                                className="col-sm-12 col-xs-12 mt-4"
                            />

                            <FormikControl control="input" formik={formik} type="text"
                                label="Email" name="email"
                                className="col-sm-12 col-xs-12 mt-4"
                            />

                            <FormikControl control="input" formik={formik} type="password"
                                label="Choose Password" name="password"
                                className="col-sm-12 col-xs-12 mt-4"
                            />
                          
                          <button type="submit" className="btn btn-primary mt-4 float-end btn-lg">Submit</button>

                          
                        </Form>
                        
                      )
                    }
                  }
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  )
}


const mapStateToProps = (state) =>{
  return {
    auth:state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startSession : () => {
      dispatch( startSession());
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AlumniRegister);
