import api from "../api/api";


const getDetails = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/user/alumni/details").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}



const alumniDashboardServices = {
    getDetails,


}
export default alumniDashboardServices;