import React, { useEffect, useState } from 'react'
import withAlert from '../../../../hoc/withAlert'
import AppLayout from '../../../../layout/components/AppLayout'



import newsServices from '../newsServices';
import moment from 'moment';
import NewsFormComponent from '../components/NewsFormComponent';


function NewsListPage({AppAlert}) {
    const [searchingInProgress, setSearchingStatus] = useState(false);
    const [newsList,setNewsList]    = useState(null);


    const getNewsList = () => {
        setSearchingStatus(true)
        newsServices.get().then(res =>{
            setNewsList(res.data);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSearchingStatus(false);
        })
    }

    
    useEffect(()=>{
        getNewsList();
    },[])


    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row pt-4">
                    <div className="col-md-6">
                        <h3>News List </h3>
                    </div>
                    <div className="col-md-6">
                    <span className="float-end"> <NewsFormComponent AppAlert={AppAlert} refreshList={getNewsList} /> </span>
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {newsList && newsList.length > 0 ? 
                            newsList.map(newsDetails=>
                            <div className="row mt-2" key={newsDetails.id}>
                                <div className="co-md-12">
                                    <div className="card">
                                        <div className="card-header bg-primary ">
                                            <strong className="text-white">{newsDetails.title}</strong>
                                            <span className="float-end">
                                                <NewsFormComponent AppAlert={AppAlert} refreshList={getNewsList} data={newsDetails} />
                                            </span>
                                            <span className="float-end">
                                                {newsDetails.publish === 0 ? 
                                                    <span className="badge bg-warning text-dark">Drafted</span>
                                                    :
                                                    <span className="badge bg-success text-white">Published</span>
                                                    }
                                            </span>
                                        </div>
                                        <div className="card-body mb-0 pb-0">
                                            <p>{newsDetails.details}</p>
                                        </div>

                                        <div className="card-body mb-0 pb-0">
                                            <div class="d-flex justify-content-between">
                                                <h4 className="me-2">Image:</h4>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            )
                            :
                            <div className="row">
                                <div className="co-md-12 ">
                                    {Array.isArray(newsList) && <h3>No Record Found</h3> }
                                </div>
                            </div>
                            
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(NewsListPage)