import React from 'react'
import moment from 'moment';


function AlumniNewsList({newsList}) {
    return (
        <div className="row mt-3">
            <div className="col-md-12">
                <h4 className="text-danger">Recent News</h4>
            </div>
            <div className="col-md-12">
                {newsList && newsList.map(news=>
                    <div className="card mb-2" key={news.id}>
                        <div className="card-header">
                            <strong>{news.title}</strong>
                        </div>
                        <div className="card-body">
                            <h5 className="blockquote-footer m-2">{ moment(news.created_at).format("LL")}</h5>
                            <p>{news.details}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AlumniNewsList