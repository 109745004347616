import React, { useEffect, useState } from 'react'
import withAlert from '../../../../hoc/withAlert'

import systemMasterServices from '../../../../master/systemMasterServices';


import { CircularProgress } from '@mui/material';
import eventServices from '../eventServices';
import moment from 'moment';
import EventFormComponent from '../components/EventFormComponent';
import AppLayout from '../../../../layout/components/AppLayout';


function EventListPage({AppAlert}) {
    
    
    const [searchingInProgress, setSearchingStatus] = useState(false);

    


    
    const [eventList,setEventList]    = useState(null);
    const getEventList = () => {
        setSearchingStatus(true)
        eventServices.get().then(res =>{
            setEventList(res.data);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSearchingStatus(false);
        })
    }

    useEffect(()=>{
        getEventList();
    },[])

    



    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row pt-4">
                    <div className="col-md-6">
                        <h3>Event List </h3>
                    </div>
                    <div className="col-md-6">
                    <span className="float-end"><EventFormComponent AppAlert={AppAlert} refreshList={getEventList} /></span>
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {eventList && eventList.length > 0 ? 
                            eventList.map(eventDetails=>
                            <div className="row mt-2" key={eventDetails.id}>
                                <div className="co-md-12">
                                    <div className="card">
                                        <div className="card-header bg-primary ">
                                            <strong className="text-white">{eventDetails.title}</strong>
                                            <span className="float-end">
                                                <EventFormComponent AppAlert={AppAlert} refreshList={getEventList} data={eventDetails} /> 
                                            </span>
                                            <span className="float-end">
                                                {eventDetails.publish === 0 ? 
                                                    <span className="badge bg-warning text-dark">Drafted</span>
                                                    :
                                                    <span className="badge bg-success text-white">Published</span>
                                                    }
                                            </span>
                                        </div>
                                        <div className="card-body mb-0 pb-0">
                                            <p>{eventDetails.details}</p>
                                        </div>
                                        <div className="card-footer">
                                            Event Start Date : <strong>{ moment(eventDetails.eventStartDate).format("LL")} </strong> 
                                            Event End Date : <strong>{ moment(eventDetails.eventEndDate).format("LL")} </strong>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                            :
                            <div className="row">
                                <div className="co-md-12 ">
                                    {Array.isArray(eventList) && <h3>No Record Found</h3> }
                                </div>
                            </div>
                            
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(EventListPage)