import { Cancel, CancelRounded, CheckCircle } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent,  IconButton } from '@mui/material';
import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router';
import withAlert from '../../../../hoc/withAlert';
import AppLayout from  '../../../../layout/components/AppLayout'
import adminAlumniServices from '../adminAlumniServices';


const LabelValue= ({label,value}) =>{
    return(
        <div className="row">
            <div className="col-md-6"><strong>{label} : </strong></div>
            <div className="col-md-6"><div><span>{(value === null || value === "" ) ? "--NA--" : value }</span></div></div>
        </div>
    )
}

function AlumniDetailsPage({AppAlert}) {

    let { id } = useParams();
    const [alumniDetails,setAlumniDetails]    = useState(null);
    const [loading, setLoadingStatus]           = useState(false);

    const getAlumniDetails = () =>{
        setLoadingStatus(true);
        adminAlumniServices.getById(id).then(res=>{
            setAlumniDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    useEffect(()=>{
        getAlumniDetails();
    },[id])

    const [dialogStatus,setDialogStatus]    = useState(false);
    const handleClose = () =>{
        setDialogStatus(false);
    }


    const updateStatus = (status) =>{
        adminAlumniServices.updateStatus({id:alumniDetails.id,status:status }).then(res=>{
            AppAlert("Status update","success")
            getAlumniDetails();
        }).catch(error=>{
            AppAlert("Someting went wrong");
        }).finally(()=>{
            setDialogStatus(false);
        })
    }

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col-md-3">
                        {alumniDetails &&
                        <div>
                            { alumniDetails.profileImage === null ? 
                                <div>
                                    <img class="v2-image" src="/images/blank-profile.png" />
                                </div>
                                :
                                <div>
                                    <img class="v2-image" src={alumniDetails.profileImage} />
                                </div>
                            }
                        </div>
                        }
                    </div>
                    {alumniDetails &&
                    <div className="col-md-9">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <LabelValue label="Student Name" value={alumniDetails.firstName+" "+alumniDetails.lastName} />
                                        <LabelValue label="Batch" value={alumniDetails.batch} />
                                        <LabelValue label="School No" value={alumniDetails.schoolNo} />
                                        <LabelValue label="Phone" value={alumniDetails.phone} />
                                        <LabelValue label="Email" value={alumniDetails.email} />
                                    </div>
                                    <div className="col-md-6">
                                        <LabelValue label="Work" value={alumniDetails.currentWork} />
                                        <LabelValue label="Work Location" value={alumniDetails.currentWorkLocation} />
                                        <IconButton onClick={()=>{setDialogStatus(true)}}>
                                            {alumniDetails.status == 1 &&
                                                <CheckCircle  color="success" sx={{fontSize:40 }} />
                                            }
                                            {alumniDetails.status == 2 &&
                                                <CancelRounded sx={{ color: "#F00", fontSize:40 }} />
                                            }

                                            {alumniDetails.status == 0 &&
                                                <button type="button" className="btn btn-warning">Verification Pending</button>
                                            }
                                        </IconButton>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <hr/>
                                    <h3>Current Address</h3>
                                    <p>
                                        {alumniDetails.addressLine1} <br/>
                                        {alumniDetails.addressLine2} <br/>
                                        {alumniDetails.city}, {alumniDetails.state} {alumniDetails.pincode}
                                    </p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Verification status"}</DialogTitle>
                <DialogContent>
                    <div className="d-flex justify-content-around">
                        <button onClick={()=>updateStatus(1)} type="button" className="btn btn-success">Approve</button>
                        <button onClick={()=>updateStatus(2)} type="button" className="btn btn-danger">Reject</button>
                    </div>
                </DialogContent>
            </Dialog>
      </AppLayout>
    )
}

export default withAlert(AlumniDetailsPage)