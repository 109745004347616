import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/FormikControl/FormikControl';
import { Add, Edit } from '@mui/icons-material';


import moment from 'moment';
import alumniProfileServices from '../alumniProfileServices';

const UpdateProfileForm = ({data, AppAlert,refreshList}) => {
    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);

    const initialValues = {
        batch :'',
        firstName :'',
        lastName:'',
        schoolNo: '',
        phone:'',
        currentWork:"",
        currentWorkLocation:"",
        addressLine1:"",
        addressLine2:"",
        city:"",
        state:"",
        pincode:"",

      }
    
      const validationRule = Yup.object({
        batch: Yup.string().required("Required"),
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        phone: Yup.string().required("Required"),
        schoolNo: Yup.string().required("Required"),
      });

    const addNew = ()=>{
        setFormValues(initialValues);
        setDialogStatus(true)
    }

    const updateData = ()=>{
        let autoFill = {
            id:data.id,
            firstName:data.firstName,
            lastName:data.lastName,

            batch:data.batch === null ? "" : data.batch,
            schoolNo:data.schoolNo === null? "" : data.schoolNo,
            phone:data.phone === null ? "" : data.phone,

            currentWork:data.currentWork === null ? "" : data.currentWork,
            currentWorkLocation:data.currentWorkLocation === null ? "" : data.currentWorkLocation,
            addressLine1:data.addressLine1 === null ? "" : data.addressLine1,
            addressLine2:data.addressLine2 === null ? "" : data.addressLine2,
            city:data.city === null ? "" : data.city,
            state:data.state === null ? "" : data.state,
            pincode:data.pincode === null ? "" : data.pincode,
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true)
        alumniProfileServices.save(values).then(res =>{
            AppAlert(res.msg);
            refreshList();
            setDialogStatus(false);
        }).catch((error)=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }

    useEffect(()=>{
        if(data.schoolNo === null ){
            updateData();
        }
    },[data])


    return (
        <>
            {data &&
                <Tooltip title="Update">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            }
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Holiday List"}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            <div className="col-md-12">
                                                <div className="row">

                                                    <div className="col-md-6 mt-4">
                                                        <FormikControl control="input" formik={formik} type="text"
                                                            label="School No *" name="schoolNo"
                                                        />
                                                    </div>

                                                    <div className="col-md-6 mt-4">
                                                        <FormikControl control="input" formik={formik} type="text"
                                                            label="Batch *" name="batch"
                                                        />
                                                    </div>

                                                    <div className="col-md-4 mt-4">
                                                        <FormikControl control="input" formik={formik} type="text"
                                                            label="First Name *" name="firstName"
                                                        />
                                                    </div>

                                                    <div className="col-md-4 mt-4">
                                                        <FormikControl control="input" formik={formik} type="text"
                                                            label="Last Name* " name="lastName"
                                                        />
                                                    </div>

                                                    

                                                    <div className="col-md-4 mt-4">
                                                        <FormikControl control="input" formik={formik} type="text"
                                                            label="Phone*" name="phone"
                                                        />
                                                    </div>


                                                    <div className="col-md-6 mt-4">
                                                        <FormikControl control="input" formik={formik} type="text"
                                                            label="Currently Working As" name="currentWork"
                                                        />
                                                    </div>

                                                    <div className="col-md-6 mt-4">
                                                        <FormikControl control="input" formik={formik} type="text"
                                                            label="Currently Work Location" name="currentWorkLocation"
                                                        />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <h4>Home Address</h4>
                                                    </div>
                                                    <div className="col-md-12 mt-4">
                                                        <FormikControl control="input" formik={formik} type="text"
                                                            label="AddressLine 1" name="addressLine1"
                                                        />
                                                    </div>

                                                    <div className="col-md-12 mt-4">
                                                        <FormikControl control="input" formik={formik} type="text"
                                                            label="AddressLine 2" name="addressLine2"
                                                        />
                                                    </div>

                                                    <div className="col-md-4 mt-4">
                                                        <FormikControl control="input" formik={formik} type="text"
                                                            label="City" name="city"
                                                        />
                                                    </div>

                                                    <div className="col-md-4 mt-4">
                                                        <FormikControl control="input" formik={formik} type="text"
                                                            label="State" name="state"
                                                        />
                                                    </div>

                                                    <div className="col-md-4 mt-4">
                                                        <FormikControl control="input" formik={formik} type="text"
                                                            label="Pincode" name="pincode"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                        { /*  JSON.stringify(formik.values) */}
                                        </div>
                                        {savingInProgress && <div className="row"><div className="col-md-12 p-4"><LinearProgress/></div></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    
    )
}

export default UpdateProfileForm