
import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import AlumniEventsList from '../../app/alumni/communication/components/AlumniEventsList';
import AlumniNewsList from '../../app/alumni/communication/components/AlumniNewsList';
import comunicationServices from '../../app/alumni/communication/comunicationServices';
import AppLayout from '../../layout/components/AppLayout'
import alumniDashboardServices from '../alumniDashboardServices';

function AlumniDashboard() {
    const [alumniDetails,setAlumniDetails] = useState(null);
    const getDetails = () =>{
        alumniDashboardServices.getDetails().then(res=>{
            setAlumniDetails(res.data);
        })
    }

    useEffect(()=>{
        getDetails();
    },[])



    const [eventsList,setEventsList]    = useState(null);
    const getEventsList = () => {
        comunicationServices.getEventsList().then(res =>{
            setEventsList(res.data);
        }).catch((error)=>{
        }).finally(()=>{
        })
    }

    const [newsList,setNewsList]    = useState(null);
    const getNewsList = () => {
        comunicationServices.getNewsList().then(res =>{
            setNewsList(res.data);
        }).catch((error)=>{
        }).finally(()=>{
        })
    }


    useEffect(()=>{
        getEventsList();
        getNewsList();
    },[]);

    return (
        alumniDetails &&
        <AppLayout>
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col-md-12">
                        <h3>Hello, <span className="text-success"> {alumniDetails.firstName} {alumniDetails.lastName} </span></h3>
                    </div>
                </div>
                
                {alumniDetails.status === 0 &&
                    <div className="row mt-3">
                        <div className="col-md-12 text-center">
                            { alumniDetails.schoolNo !== null &&
                                <div className="alert alert-warning" role="alert">
                                    <h4 className="alert-heading">Verification Pending!</h4>
                                    <p>Your Verification is pending,</p>
                                    <p>You will receive an email when your registration is complete,</p>
                                </div>
                            }
                        </div>

                        <div className="col-md-12 text-center">
                            { alumniDetails.schoolNo === null &&
                                <div className="alert alert-info" role="alert">
                                    <h3> <Link to="/my/profile">Click here</Link> and complete your registration process</h3>
                                </div>
                            }
                        </div>
                    </div>
                }

                {alumniDetails.status === 1 &&
                    <div className="row mt-2">
                        <div className="col-md-12">
                            {eventsList && <AlumniEventsList eventsList={eventsList} />}
                        </div>
                        <div className="col-md-12">
                            {newsList && <AlumniNewsList newsList={newsList} />}
                        </div>
                    </div>
                }


                {alumniDetails.status === 2 &&
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <div className="alert alert-danger" role="alert">
                                <h4> Your Alumni registration rejected, Please verify your details and submit your request again.</h4>
                                <h5> <Link to="/my/profile">Click here</Link> and update profile information</h5>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </AppLayout>
    )
}

export default AlumniDashboard