import React,{useEffect,useState,useRef} from 'react'
import AppLayout from  '../../../../layout/components/AppLayout'
import adminAlumniServices from '../adminAlumniServices';
import useMasterList from '../../../../hooks/useMasterList'

import { CancelRounded, CheckCircle } from '@mui/icons-material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import moment from 'moment';
import { LinearProgress } from '@mui/material';
import { FileDownload } from '@mui/icons-material';

import { Link } from 'react-router-dom';

const CellLink = (props) =>{
    return (
        <>
            {props && <Link to={`/alumni/details/${props.data.id}`}>{props.data.schoolNo}</Link> }
        </>
    )
}

const CellStatus = (props) =>{
    return (
        <>
            {props.data.status == 1 ?
                <CheckCircle color="success"  />
                :
                <CancelRounded sx={{ color: "#F00" }} />
            }
        </>
    )
}


function AlumniListPage() {
    const [alumniList,setAlumniList] = useState([]);
    const gridRef = useRef();

    const [defaultColDef] = useState({
        floatingFilter: true,
        filterParams: {
          buttons: ['apply'],
        },
        resizable: true,
        width:120,
        filter:true,
        sortable: true,
        cellStyle: { 'textTransform': 'uppercase' },
    })


    const [columnDefs,setColumnDefs] = useState([]);
    const setupColDef = () =>{
        setColumnDefs([
            {headerName: "Sr No",valueGetter: "node.rowIndex + 1"},
            {field:"Name", valueGetter: params => params.data.firstName+" "+params.data.lastName, width:180  },
            {field:"schoolNo",  valueGetter: params => params.data.admissionNo, cellRenderer: CellLink,},
            {field:"batch"},
            {field:"status",   valueGetter: params => params.data.admissionNo, cellRenderer: CellStatus},
        ]);
    }

    const onGridReady =(params)=>{
        getAlumniList();
    }

    const [loading,setLoadingStatus] = useState(true);

    const getAlumniList = () =>{
        setLoadingStatus(true);
        adminAlumniServices.getList().then(res=>{
            setAlumniList(res.data);
            setLoadingStatus(false);
            setupColDef()
        }).catch(error=>{
            setAlumniList([]);
        })
    }

    const onBtnExport = () => {
        gridRef.current.api.exportDataAsCsv();
    }

    const [masters,getMasterLabel] = useMasterList(['gender'])

    return (
        <AppLayout>
            <div className="container-fluid mt-3">
                {masters && 
                    <div className="row">
                        <div className="col-md-9 mb-2">
                            <h3>Alumin List</h3>
                        </div>
                        <div className="col-md-3 mb-2">
                            <button type="button" onClick={onBtnExport} className="btn btn-primary float-end"> <FileDownload/> Export</button>
                        </div>
                        <div className="col-md-12">
                        {loading && <LinearProgress  /> }
                        </div>
                        <div className="col-md-12">
                        <div className="ag-theme-alpine" style={{height: 550, width:"auto"}}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={alumniList}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    animateRows={true}
                                    onGridReady={onGridReady}
                                    >
                                </AgGridReact>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </AppLayout>
    )
}

export default AlumniListPage