import React, { useState } from 'react'

function AlumniForgotPassword() {

    const [emailId,setEmailId] = useState("");
    const [OTPSent,setOTPSentStatus] = useState("");
    const [OTP,setOTP] = useState("");

    const [resendCount,sendResendCount] = useState(0);



    const requestOTP = () =>{
        setOTPSentStatus(true);
    }

    const submitOTP = () =>{
        
    }


    return (
        <div className="container">
            <div className="row  mt-5">
                <div className="col-6 offset-3">
                    <div className="card">
                        <div className="card-body text-center">
                            <h3>Forgot password</h3>
                            <div className="row">
                                <div className="col-md-12">
                                    <input type="text" className="form-control mt-5" placeholder="Registed Emai ID"
                                        value={emailId}
                                        onChange={(e)=>setEmailId(e.target.value)}
                                    />
                                    {OTPSent &&
                                        <input type="text" className="form-control mt-5" placeholder="Enter OTP"
                                        value={OTP}
                                        onChange={(e)=>setOTP(e.target.value)}
                                    />
                                    }
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    {OTPSent ? 
                                        <button type="button" className="btn btn-primary" onClick={submitOTP} >Submit OTP</button>
                                        :
                                        <button type="button" className="btn btn-primary" onClick={requestOTP} >
                                            Request OTP

                                        </button>
                                    }
                                </div>
                            </div>
                            
                            {OTP}
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlumniForgotPassword