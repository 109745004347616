import api from "../api/api";


const getDashboardData = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/admin/alumni/dashboad/details").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const adminDashboardServices = {
    getDashboardData,
}
export default adminDashboardServices;