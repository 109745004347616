import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import AlumniEventsList from '../../app/alumni/communication/components/AlumniEventsList';
import AlumniNewsList from '../../app/alumni/communication/components/AlumniNewsList';
import comunicationServices from '../../app/alumni/communication/comunicationServices';
import AppLayout from '../../layout/components/AppLayout'
import adminDashboardServices from '../adminDashboardServices';



function AdminDashboard() {

    const [dashboardData,setDashboardData] = useState(null);

    const getDashboardData = () => {
        adminDashboardServices.getDashboardData().then(res=>{
            setDashboardData(res.data)
        })
    }

    useEffect(()=>{
        getDashboardData();
    },[])

    return (
        <AppLayout>
            <div className="container-fluid">
                {dashboardData &&
                    <div className="row mt-3">
                        
                        <div className="col-md-3 col-sm-6 col-xs-6">
                            <Link to={"/alumni/list"} className="card bg-primary text-white shadow  p-1" style={{textDecoration:"none"}}>
                                <p>Total Registration</p>
                                <span className="text-center"><h1>{JSON.stringify(dashboardData.counts.totalRegistration)}</h1></span>
                            </Link>
                        </div>

                        <div className="col-md-3 col-sm-6 col-xs-6">
                            <Link to={"/alumni/registration/request"} className="card bg-warning shadow  p-1" style={{textDecoration:"none"}}>
                                <p>New Registration</p>
                                <span className="text-center"><h1>{JSON.stringify(dashboardData.counts.pendingRequest)}</h1></span>
                            </Link>
                        </div>

                        <div className="col-md-3 col-sm-6 col-xs-6">
                            <Link to={"/alumni/list"} className="card bg-success text-white shadow  p-1" style={{textDecoration:"none"}}>
                                <p>Verified</p>
                                <span className="text-center"><h1>{JSON.stringify(dashboardData.counts.approved)}</h1></span>
                            </Link>
                        </div>

                        <div className="col-md-3 col-sm-6 col-xs-6">
                            <Link to={"/alumni/list"} className="card bg-danger text-white shadow  p-1" style={{textDecoration:"none"}}>
                                <p>Rejected</p>
                                <span className="text-center"><h1>{JSON.stringify(dashboardData.counts.rejected)}</h1></span>
                            </Link>
                        </div>
                        
                        
                    </div>
                }

                {dashboardData &&


                    <div className="row mt-3">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">News</div>
                                <div className="card-body">
                                    <ul>
                                        {dashboardData.newsList.length > 0 && dashboardData.newsList.map(news=>
                                            <li key={news.id}> 
                                                <strong>{news.title} </strong>
                                                {news.publish === 0 ? 
                                                    <span className="badge bg-warning text-dark">Drafted</span>
                                                    :
                                                    <span className="badge bg-success text-white">Published</span>
                                                }
                                            </li>
                                        )}
                                        { dashboardData.newsList.length == 0  &&
                                            <li> <h4>No News found </h4></li>
                                        }
                                    </ul>
                                </div>
                                <div className="card-footer">
                                    <Link to={"/news/list"} className="btn btn-primary">Manage News</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">Upcomming Events</div>
                                <div className="card-body">
                                    <ul>
                                        {dashboardData.eventList.length > 0 && dashboardData.eventList.map(event=>
                                            <li key={event.id}> 
                                                <strong>{event.title} </strong>
                                                {event.publish === 0 ? 
                                                    <span className="badge bg-warning text-dark">Drafted</span>
                                                    :
                                                    <span className="badge bg-success text-white">Published</span>
                                                }
                                            </li>
                                        )}
                                        { dashboardData.eventList.length == 0  &&
                                            <li> <h4>No Event found </h4></li>
                                        }
                                    </ul>
                                </div>
                                <div className="card-footer">
                                <Link to={"/events/list"} className="btn btn-primary">Manage News</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
      </AppLayout>
    )
}

export default AdminDashboard