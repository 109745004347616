import React,{useState,useEffect} from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import alumniProfileServices from '../alumniProfileServices';
import TextInline from '../../../../components/InlineEdit/TextInline';
import ImageInline from '../../../../components/InlineEdit/ImageInline';
import withAlert from '../../../../hoc/withAlert';
import UpdateProfileForm from '../components/UpdateProfileForm';
import { Link } from 'react-router-dom';
import { Cancel, CancelRounded, CheckCircle } from '@mui/icons-material';



const LabelValue= ({label,value}) =>{
    return(
        <div className="row">
            <div className="col-md-6"><strong>{label} : </strong></div>
            <div className="col-md-6"><div><span>{(value === null || value === "" ) ? "--NA--" : value }</span></div></div>
        </div>
    )
}

function AlumniProfilePage({AppAlert}) {

    const [alumniDetails,setAlumniDetails] = useState(null);
    const getDetails = () =>{
        alumniProfileServices.getDetails().then(res=>{
            setAlumniDetails(res.data);
        })
    }
   
    useEffect(()=>{
        getDetails();
    },[])

    const resendRequest = () =>{
        alumniProfileServices.sendVerificationRequest().then(res=>{
            getDetails();
        })
    }

    return(
        <AppLayout>
            <div className="container-fluid">
                {alumniDetails &&
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <ImageInline
                                lable="Profile Image"
                                name="profileImage"
                                value={alumniDetails.profileImage}
                                editId={alumniDetails.id}
                                updateInline={alumniProfileServices.updateInline}
                                AppAlert={AppAlert}
                                module='alumni'
                            />
                        </div>
                        
                        <div  className="col-md-9">
                            <div className="card">
                                <div className="card-header">
                                    <span className="fw-bold"> My Profile </span>
                                    <span className="float-end">
                                        <UpdateProfileForm
                                            data={alumniDetails}
                                            AppAlert={AppAlert}
                                            refreshList={getDetails}
                                        />
                                    </span>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <LabelValue label="Student Name" value={alumniDetails.firstName+" "+alumniDetails.lastName} />
                                            <LabelValue label="Batch" value={alumniDetails.batch} />
                                            <LabelValue label="School No" value={alumniDetails.schoolNo} />
                                            <LabelValue label="Phone" value={alumniDetails.phone} />
                                            <LabelValue label="Email" value={alumniDetails.email} />

                                            <Link to="/auth/change-password" className="btn btn-primary btn-sm">Change Password</Link>
                                        </div>
                                        <div className="col-md-6">
                                            <LabelValue label="Work" value={alumniDetails.currentWork} />
                                            <LabelValue label="Work Location" value={alumniDetails.currentWorkLocation} />
                                            <div className="row mt-4">
                                                <div className="col-md-6">
                                                    <strong>Verification status : </strong>
                                                </div>
                                                <div className="col-md-6">
                                                    {alumniDetails.status == 1 &&
                                                        <CheckCircle  color="success" sx={{fontSize:40 }} />
                                                    }
                                                    {alumniDetails.status == 2 &&
                                                        <>
                                                            <CancelRounded sx={{ color: "#F00", fontSize:40 }} />
                                                            <button type="button" className="btn btn-primary" onClick={resendRequest} >Resend Request</button>
                                                        </>
                                                    }

                                                    {alumniDetails.status == 0 &&
                                                        <button type="button" className="btn btn-warning">Verification Pending</button>
                                                    }
                                                </div>

                                            </div>
                                                
                                        </div>
                                        <div className="col-md-12">
                                            <hr/>
                                            <h3>Current Address</h3>
                                            <p>
                                                {alumniDetails.addressLine1} <br/>
                                                {alumniDetails.addressLine2} <br/>
                                                {alumniDetails.city}, {alumniDetails.state} {alumniDetails.pincode}
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                }
            </div>
        </AppLayout>
    )
}

export default withAlert(AlumniProfilePage)



/*

<div className="row">
                                <div className="col-md-3">
                                    <span className="fw-bold">Name</span>
                                </div>
                                <div className="col-md-9">
                                    <TextInline
                                        name="firstName" 
                                        editId={alumniDetails.id}
                                        value={alumniDetails.firstName}
                                        updateInline={alumniProfileServices.updateInline}
                                    />
                                    <TextInline
                                        name="lastName" 
                                        editId={alumniDetails.id}
                                        value={alumniDetails.lastName}
                                        updateInline={alumniProfileServices.updateInline}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <span className="fw-bold">School No</span>
                                </div>
                                <div className="col-md-9">
                                    <TextInline
                                        name="schoolNo" 
                                        editId={alumniDetails.id}
                                        value={alumniDetails.schoolNo}
                                        updateInline={alumniProfileServices.updateInline}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <span className="fw-bold">Batch</span>
                                </div>
                                <div className="col-md-9">
                                    <TextInline
                                        name="batch" 
                                        editId={alumniDetails.id}
                                        value={alumniDetails.batch}
                                        updateInline={alumniProfileServices.updateInline}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <span className="fw-bold">Email</span>
                                </div>
                                <div className="col-md-9">
                                    <TextInline
                                        name="email" 
                                        editId={alumniDetails.id}
                                        value={alumniDetails.email}
                                        updateInline={alumniProfileServices.updateInline}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <span className="fw-bold">Phone</span>
                                </div>
                                <div className="col-md-9">
                                    <TextInline
                                        name="phone" 
                                        editId={alumniDetails.id}
                                        value={alumniDetails.phone}
                                        updateInline={alumniProfileServices.updateInline}
                                    />
                                </div>
                            </div>


*/