import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'

function LandingPage() {


    return (
        <div className="container-fluid">
            <div className="row mt-5" >
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h2>Rai School</h2>
                            <h3>Alumni</h3>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h4>Search and network with alums across programs and batches</h4>
                            <Link to="/auth/login" className="btn btn-primary float-end" >Login / Sign up</Link>
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h2>RAIST DAY</h2>
                            <p>Those who cannot physically attend the Raist Day on 26.11.2023 (Sunday) are requested to send their video message to email – mnssraist@gmail.com (file size should not exceed 25 MB; Also mention their name alongwith batch year).</p>
                            <p><span className="float-end">PRINCIPAL & DIRECTOR<br/>MNSS, RAI</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPage