import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router'
import AdminLogin from '../auth/pages/AdminLogin'
import AlumniLogin from '../auth/pages/AlumniLogin'
import AlumniRegister from '../auth/pages/AlumniRegister'


import Logout from '../auth/pages/Logout'



import UserListPage from '../users/pages/UserListPage'
import UserRolePage from '../users/pages/UserRolePage'
import SystemMasterListPage from '../master/pages/SystemMasterListPage'
import SchoolSettingsPage from '../app/settings/pages/SchoolSettingsPage'


import LandingPage from '../landing/pages/LandingPage'
import { useSelector } from 'react-redux'


import AlumniDashboard from '../dashboard/pages/AlumniDashboard'
import AdminDashboard from '../dashboard/pages/AdminDashboard'
import AlumniChangePassword from '../auth/pages/AlumniChangePassword'
import AlumniListPage from '../app/admin/alumni/pages/AlumniListPage'
import AlumniRegistrationRequestPage from '../app/admin/alumni/pages/AlumniRegistrationRequestPage'
import AlumniDetailsPage from '../app/admin/alumni/pages/AlumniDetailsPage'
import AlumniProfilePage from '../app/alumni/profile/pages/AlumniProfilePage'
import EventListPage from '../app/admin/events/pages/EventListPage'
import NewsListPage from '../app/admin/news/pages/NewsListPage'
import AlumniNewsPage from '../app/alumni/communication/pages/AlumniNewsPage'
import AlumniEventsPage from '../app/alumni/communication/pages/AlumniEventsPage'
import AlumniForgotPassword from '../auth/pages/AlumniForgotPassword'





export default function AppRoutes() {

    const authUser = useSelector((state) => state.auth)
    
    const [userRole,setUserRole] = useState(0); 
    const getUserRole = () =>{
        if(authUser){
            let user_role = authUser['role'] === undefined ? 0 : authUser['role']
            setUserRole(user_role);
        }
    }
    
    useEffect(()=>{
        getUserRole();
    },[authUser])
    


    
    return (
    <>
        <Routes>

            <Route path="/auth/login" element={ <AlumniLogin /> } />
            <Route path="/auth/register" element={ <AlumniRegister /> } />
            <Route path="/auth/forgot-password" element={ <AlumniForgotPassword /> } />
            <Route path="/auth/admin/login" element={ <AdminLogin /> } />
            <Route path="/auth/logout" element={ <Logout/> } />

            {userRole === 0 &&  <Route path="/" element={ <LandingPage/> } /> }

            {/* Admin Routes */}
            {userRole === 1 &&  <Route path="/" element={ <AdminDashboard /> } /> }
            {userRole === 1 && <Route path="/dashboard" element={ <AdminDashboard /> } /> }
            {userRole === 1 && <Route path="/system-master/list" element={ <SystemMasterListPage /> } /> }
            {userRole === 1 && <Route path="/settings/school" element={ <SchoolSettingsPage /> } /> }
            {userRole === 1 && <Route path="/user/list" element={ <UserListPage/> } /> }
            {userRole === 1 && <Route path="/user/role" element={ <UserRolePage/> } /> }

            {userRole === 1 && <Route path="/alumni/list" element={ <AlumniListPage /> } /> }
            {userRole === 1 && <Route path="/alumni/registration/request" element={ <AlumniRegistrationRequestPage /> } /> }
            {userRole === 1 && <Route path="/alumni/details/:id" element={ <AlumniDetailsPage/> } /> }

            {userRole === 1 && <Route path="/events/list" element={ <EventListPage /> } /> }
            {userRole === 1 && <Route path="/news/list" element={ <NewsListPage /> } /> }


            

            
            {/* Alumni Routes */}
            {userRole === 4 &&  <Route path="/" element={ <AlumniDashboard /> } /> }
            {userRole === 4 &&  <Route path="/dashboard" element={ <AlumniDashboard /> } /> }
            {userRole === 4 &&  <Route path="/my/profile" element={ <AlumniProfilePage /> } /> }
            {userRole === 4 &&  <Route path="/auth/change-password" element={ <AlumniChangePassword /> } /> }

            {userRole === 4 &&  <Route path="/news" element={ <AlumniNewsPage /> } /> }
            {userRole === 4 &&  <Route path="/events" element={ <AlumniEventsPage /> } /> }
            
            
        </Routes>
    </>
    )
}